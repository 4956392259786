import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { getUser, signIn as sendSignInRequest, signOut as sendSignOutRequest } from "../api/auth";

function AuthProvider(props) {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("quser")));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      try {
        const result = await getUser();
        if (result.isOk) {
          setUser(result.data);
        } else {
          setUser(null);
        }
      } catch (e) {
        console.log(e);
        setUser(null);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result.data);
      sessionStorage.setItem("quser", JSON.stringify(result.data));
    } else {
      setUser(null);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser();
    sessionStorage.removeItem("quser");
    sendSignOutRequest();
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, loading }}
      {...props}
    />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
