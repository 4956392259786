import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { Box, Link } from '@mui/material';
import { useHistory } from 'react-router-dom';

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
  const history = useHistory();

  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={'before'}
          cssClass={'header-title'}
          text={title}
          visible={!!title}
        />
        <Item location={'before'}>
          <Box sx={{
            display: 'flex',
          }}>
            <Link to={'/'} sx={{
              fontSize: '24px',
              color: '#434343 !important',
              ml: 3,
              cursor: 'pointer',
              textDecoration: 'none',
              '&:hover': {
                opacity: 0.8
              }
            }} onClick={() => history.goBack()}>
              <i className="dx-icon dx-icon-arrowleft"
                style={{
                  color: 'white',
                  background: '#1976d2',
                  fontSize: '20px',
                  padding: '0.5rem',
                  borderRadius: '100px'
                }} />
            </Link>
            <Link to={'/'} sx={{
              color: 'white !important',
              ml: 1,
              cursor: 'pointer',
              background: '#1976d2',
              borderRadius: '100px',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                opacity: 0.8
              }
            }} onClick={() => history.push("/")}>
              <i className="dx-icon dx-icon-home"
                style={{
                  fontSize: '20px',
                  padding: '0.5rem'
                }} >
              </i>
              <span style={{
                fontFamily: 'inherit',
                fontSize: '16px',
                paddingRight: '0.5rem'
              }}>Dashboard</span>
            </Link>
          </Box>
        </Item>
        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            width={210}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
    </header>
  )
}
